@page {
  size: A4;
  margin: 0;
}

.title {
  text-align: center;
}

.personal {
  margin: 20px 0;
}
.personal > table {
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: large;
  line-height: 5mm;
  align-items: center;
  text-transform: uppercase;
  width: -webkit-fill-available;
}
.personal > table td.value {
  text-decoration: underline;
}

h1 {
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: large;
  line-height: 5mm;
  align-items: center;
  text-transform: uppercase;
}

.caract-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2mm;
}

.caract-column {
  flex-basis: 1;
}

.caract-title {
  flex-basis: 1;
  flex-grow: 2;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 6mm;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  padding-right: 2em;

  color: #000000;
}

h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 6mm;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  text-underline-offset: 4px;

  color: #000000;
}

.container-end {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  gap: 2mm;

  margin: 0mm 5mm 8mm 5mm;
}

table > .personalTable {
  display: table;
  border-collapse: collapse;
  text-transform: uppercase;
  page-break-inside: avoid;
}

.customers,
.observation {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: small;
  line-height: normal;
}

.customers td,
.customers th,
.observation th,
#sign th {
  border: 1px solid rgb(5, 5, 5);
  overflow: hidden;
}

.customers tr:nth-child(even) {
  background-color: #f2f2f2;
}
.customers tr:nth-child(odd) .text {
  background-color: #d9e3e6;
}
.customers tr:nth-child(odd) {
  background-color: #d9e3e6;
}

.customers tr:hover {
  background-color: #ddd;
}

.customers th {
  text-align: center;
  background-color: #ffffff;
}

.customers .text {
  text-align: left;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.observation {
  text-align: justify;
  table-layout: fixed;
  font-size: small;
  line-height: normal;
}

.observation th {
  padding: 8px;
  font-weight: normal;
}

.sign {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
  font-size: small;
  line-height: normal;
  margin-top: 3px;
}

.sign th {
  border: 1px solid rgb(5, 5, 5);
  text-align: right;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  font-weight: normal;
}

.sign th:nth-child(odd) {
  text-transform: uppercase;
  width: 17%;
  table-layout: fixed;
}
.sign th:nth-child(even) {
  text-transform: capitalize;
  width: 30%;
  table-layout: fixed;
  text-align: center;
}

.sign td {
  border: 1px solid rgb(5, 5, 5);
  padding: 4px;
  overflow: hidden;
  text-align: right;
}
.sign td:nth-child(even) {
  text-align: center;
}
