@page {
  size: A4;
  margin: 0;
}
h1,
h2 {
  font-weight: normal;
  font-size: large;
}

.title {
  text-align: center;
}

.info-personal {
  display: inline-block;
}

.info-personal .par {
  display: block;
}

section.sectionBlock {
  margin: 10px 15px;
}
section.solicitante {
  margin: 10px 15px;
}

section.personal > table > .personalTable {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: large;
  line-height: 5mm;
  align-items: center;
  text-transform: uppercase;
}
.personal > table td.value {
  text-decoration: underline;
}

.block {
  width: 100%;
  display: table;
  border-collapse: collapse;
  text-transform: uppercase;
  page-break-inside: avoid;
  text-align: center;
}

.solicitante > table,
.footer table {
  border-collapse: collapse;
}
.solicitante > table {
  text-align: center;
}
.solicitante > table th,
.solicitante > table td {
  border: 1px solid black;
  border-bottom: 1px solid black;
}

section,
.footer {
  margin-top: 1rem;
}

.block {
  border: 1px solid rgb(5, 5, 5);
  background-color: #eee5e5;
}

section.inventory > table td,
section.inventory > table th {
  border: 1px solid rgb(5, 5, 5);
  overflow: hidden;
  font-size: small;
  line-height: 5mm;
}

section.inventory > table td:first-child {
  text-align: center;
}

section.inventory > table td {
  text-align: center;
}

section.inventory > table .number {
  width: 5%;
}
section.inventory > table .ninv {
  width: 18%;
}
section.inventory > table .serie {
  width: 10%;
}
section.inventory > table .description {
  width: 40%;
}

section.inventory > table th {
  text-align: center;
}

table.datos {
  margin-top: 0.25rem;
  text-align: center;
  font-style: small;
  text-align: center;
  font-size: large;
  line-height: 5mm;
  margin-bottom: 20px;
}

.datos .director {
  width: 22%;
}
.datos .vice {
  text-transform: none;
  width: 18%;
}
.datos .autorizo {
  width: 40%;
}
