@page {
  size: 62mm 28.9mm;
  margin: 0;
}

.pdf-margin {
  margin: 0;
  padding: 0;
}

.pdf {
  width: 62mm;
  height: 28.9mm;
}

.container {
  display: flex;
  width: auto;
  height: 100%;
  font-size: xx-small;
}

.qr-container {
  height: 28.9mm;
  width: 31mm;
}

.qr {
  height: 28.9mm;
  width: 31mm;
}

.info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 28.9mm;
  width: 28.9mm;
}
