@page {
  size: A4;
  margin: 0;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500;700&display=swap");

.title {
  text-align: center;
  padding: 5px 50px;
}
.title-boleta {
  padding-top: 3pt;
  text-align: center;
}
.title-cursosExtra {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3pt;
  align-items: center;
}
.boleta-info {
  margin-left: 10px;
  padding-top: 10px;
}
.sin-margen {
  margin-left: 0;
  margin-right: 0;
}
.toUppercase {
  text-transform: uppercase;
}
.titulo-contenedor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 25px;
}
.titulo-apretao {
  justify-content: center;
}
.titulo-boleta-descargada {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.logoizquierda {
  height: 1.94cm;
}
.logoizquierda-curso {
  height: 1.94cm;
  width: 1.8cm;
}
.logo-3juntos {
  height: 3.26cm;
  width: 17.5cm;
  margin: auto 5%;
}
.logoderecha {
  width: 1.84cm;
  height: 1.95cm;
}
.educacion {
  margin: 0 18px;
}
.educacion-curso {
  height: 1.98cm;
  width: 4cm;
}
.arial {
  font-family: "Arial MT", Arial, sans-serif;
  font-size: 10pt;
}
.arialBold {
  font-family: "Arial Bold", Arial, sans-serif;
  font-size: 11pt;
}
.otraletra {
  font-family: "Microsoft Sans Serif", Arial, sans-serif;
}
.separacion p {
  padding-top: 2pt;
}
.notop {
  margin-top: -5pt;
}
.negritas {
  font-weight: bold;
}
.grande {
  font-family: "Arial Bold", Arial, sans-serif;
  font-size: 33pt;
  font-weight: bold;
}
.letrasCrenCursos {
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  font-weight: bold;
  line-height: 1.5;
}
.blue {
  color: blue;
}
.masgrande {
  font-size: 45pt;
  color: red;
  font-weight: bold;
  letter-spacing: 0.2em;
}
.masgrandeCren {
  font-family: "Montserrat", sans-serif;
  font-size: 45pt;
  color: rgb(98, 16, 50);
  font-weight: bold;
  letter-spacing: 0.2em;
}
.detalles {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.carrera {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.informacion > table td.tdarial {
  font-family: "Arial MT", Arial, sans-serif;
  font-size: 7pt;
}
.informacion-boleta > table.informacionTable-boleta td.tdarialGrande {
  font-family: "Arial MT", Arial, sans-serif;
  font-size: 9pt;
  padding-top: 3pt;
}
.informacion-boleta > table.informacionTable-boleta tr {
  display: flex;
  align-items: flex-end;
}
.informacion-boleta > table.informacionTableDerecha td.tdarialGrande {
  font-family: "Arial MT", Arial, sans-serif;
  font-size: 9pt;
  padding-top: 2pt;
}
.informacion-boleta > table.informacionTableDerecha tr {
  display: flex;
  align-items: flex-end;
}
.informacion-boleta > table td.tdarial {
  font-family: "Arial MT", Arial, sans-serif;
  font-size: 7pt;
}
.informacion {
  margin: 0 5%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.informacion-boleta {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 10px;
}
.informacionTable,
.informacionTableDerecha {
  align-items: center;
  text-transform: uppercase;
}

table.informacionTable,
table.informacionTableDerecha,
table.informacionTable-boleta {
  display: table;
  border-collapse: collapse;
  text-transform: uppercase;
  page-break-inside: avoid;
  text-align: left;
}
.informacionTable {
  margin-left: 20px;
}
.informacionTableDerecha {
  margin-right: 25px;
}
.informacion > table td.value {
  padding-left: 10px;
  text-align: left;
  letter-spacing: 0px;
  font-family: "Arial MT", Arial, sans-serif;
  font-size: 7pt;
}
.informacion-boleta > table td.value {
  padding-left: 10px;
  text-align: left;
  letter-spacing: 0px;
  font-family: "Arial MT", Arial, sans-serif;
  font-size: 7pt;
}
.informacion-boleta > table.informacionTable-boleta td.valueGrande {
  font-family: "Arial MT", Arial, sans-serif;
  font-size: 9pt;
  padding-left: 10px;
  text-align: left;
  letter-spacing: 0px;
}
.informacion-boleta > table.informacionTableDerecha td.valueGrande {
  font-family: "Arial MT", Arial, sans-serif;
  font-size: 9pt;
  padding-left: 10px;
  text-align: left;
  letter-spacing: 0px;
}
.pie {
  font-family: "Arial MT", Arial, sans-serif;
  font-size: 7pt;
  text-align: center;
}
.rojo {
  color: #bc0000;
  font-size: 10pt;
}

.impresion {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 7pt;
  line-height: 6mm;
  align-items: center;
  text-align: center;
  text-underline-offset: 4px;

  color: #000000;
}

.container-end {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  gap: 2mm;

  margin: 0mm 5mm 8mm 5mm;
}

.calificaciones {
  border-collapse: collapse;
  width: 96%;
  border-bottom: 1px solid black;
  margin: auto 2%;
}
.bottonBorder {
  border-bottom: none;
}
.bottonBorderSin {
  border-bottom: none;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
table.letrasGrandes td,
table.letrasGrandes th {
  font-family: "Arial MT", Arial, sans-serif;
  font-size: 9pt;
  letter-spacing: 0px;
  padding-top: 4.55pt;
}
table.letrasGrandes th.thcalificaciones {
  padding-top: 0;
}
.calificaciones td,
.calificaciones th {
  font-family: "Arial MT", Arial, sans-serif;
  font-size: 7pt;
  letter-spacing: 0px;
  height: 14px;
}

.thcalificaciones {
  line-height: 12px;
}
@media print {
  .calificaciones tr:nth-child(even) {
    background-color: #ffffff !important;
  }
  .calificaciones tr:nth-child(odd) {
    background-color: #d2d2d2 !important;
  }
}

.calificaciones tr:nth-child(even) {
  background-color: #ffffff !important;
}
.calificaciones tr:nth-child(odd) {
  background-color: #d2d2d2 !important;
}

.calificaciones th {
  border: 1px solid rgb(5, 5, 5);
  text-align: center;
  background-color: #ffffff;
  padding: 0 2px;
}

.calificaciones td {
  position: relative;
  border-left: 1px solid rgb(5, 5, 5);
  border-right: 1px solid rgb(5, 5, 5);
  border-bottom: 1px dashed black;
}

.calificaciones .text-nombre {
  text-align: left;
  padding-left: 5px;
  width: 35%;
}
.calificaciones .text-asignatura {
  text-align: left;
  padding-left: 5px;
  width: 82%;
}
.calificaciones .text-matricula {
  text-align: center;
  padding: 0 5px;
}
.calificaciones .text,
.calificaciones tr {
  text-align: center;
}
#promedioTR {
  background-color: white !important;
}
td.promedio {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: 1px solid rgb(5, 5, 5);
}
.letra {
  text-align: right;
  padding-right: 5px;
}

.botton {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.marginBotton {
  margin-bottom: 25px;
}
.separador {
  width: 160%;
  border-bottom: 1px solid black;
  margin: 10px auto;
  transform: translateX(-20%);
}
.firma {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-height: 200px;
}

.spaceEvenly {
  justify-content: space-evenly;
}
.botton-otro {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.sello {
  width: 13cm;
  font-family: "Microsoft Sans Serif", Arial, sans-serif;
  font-size: 10pt;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.selloCren {
  width: 13cm;
  font-family: "Montserrat";
  font-size: 10pt;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.botton-certificado {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.director {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.separador-director {
  width: 50%;
  border-bottom: 1px solid black;
}
.separador-regularizacion {
  width: 120%;
  border-bottom: 1px solid black;
  transform: translateX(-9%);
}
.separador-otro {
  width: 120%;
  border-bottom: 1px solid black;
}
.final {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  line-height: 10px;
  font-size: 8px;
}
